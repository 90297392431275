<template>
  <div v-if="isCustomResultsForm">
    <ThesisForm>
      <template #resultsView>
        <CustomResultsForm :content="customResultsData" />
      </template>
    </ThesisForm>
  </div>
  <div v-else>
    <ThesisForm />
  </div>
</template>

<script>
import CustomResultsForm from './components/CustomResultsForm.vue'

export default {
  components: {
    CustomResultsForm,
  },
  computed: {
    customResultsData() {
      return this.$quizData.results
    },
    isCustomResultsForm() {
      return this.$quizData.results.customResultsForm
    },
    questionNumber() {
      return this.$store.getters.ADVANCE_QUIZ_DATA.currentStep
    },
  },
  watch: {
    questionNumber: {
      handler() {
        if (this.questionNumber === 3 || this.questionNumber === 4) {
          this.$nextTick(() => {
            if (window.innerWidth < 768) {
              window.scrollTo(0, 830)
            } else if (window.innerWidth >= 768 && window.innerWidth < 1280) {
              window.scrollTo(0, 1000)
            } else if (window.innerWidth > 1280) {
              window.scrollTo(0, 800)
            }
          })
        }
      },
      immediate: true,
    },
  },
  mounted() {

  },
  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch('/results/INITIALIZE_RESULTS', this.$quizData.sequences)
  },
}
</script>
