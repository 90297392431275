<template>
  <form @submit="submitForm">
    <label
      class="title"
      for="first_name"
    >First Name*</label>
    <input
      id="first_name"
      v-model="formData.firstName"
      class="field-element"
      required
      name="first_name"
      type="text"
    ><br>

    <label
      class="title"
      for="last_name"
    >Last Name*</label>
    <input
      id="last_name"
      v-model="formData.lastName"
      class="field-element"
      required
      name="last_name"
      type="text"
    ><br>

    <label
      class="title"
      for="email"
    >Email*</label>
    <input
      id="email"
      v-model="formData.email"
      class="field-element"
      required
      name="email"
      type="email"
    ><br>

    <label
      class="title"
      for="phone"
    >Phone*</label>
    <input
      id="phone"
      v-model="formData.phone"
      v-mask="'###-###-####'"
      class="field-element"
      required
      name="phone"
      type="tel"
      pattern="\d{3}[\-]\d{3}[\-]\d{4}"
    ><br>

    <label
      class="title"
      for="description"
    >Comments</label><textarea
      v-model="formData.comments"
      name="description"
    /><br>

    <input
      type="submit"
      name="submit"
      class="button"
    >
  </form>
</template>
<script>

export default {
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        comments: '',
        region: 'Kansas City',
        leadSource: 'Venn',
        results: {},
      },

    }
  },
  computed: {
    getThankYouPage() {
      if (window.location.pathname.includes('/_stg')) {
        const stagingNumber = window.location.pathname.split('/')[2]
        return `https://live.macapartments.com/_stg/${stagingNumber}/thank-you`
      }
      return 'https://live.macapartments.com/thank-you'
    },
    quizResults() {
      return this.$store.getters.RESULTS
    },

  },
  mounted() {
    this.sortResults()
  },
  methods: {
    async submitForm(e) {
      e.preventDefault()
      const res = await fetch('https://formspree.io/f/mvolwrlk', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.formData),
      })

      if (res.status === 200) {
        window.location.href = this.getThankYouPage
      }
    },
    sortResults() {
      const resultsObj = {}
      this.quizResults.forEach(result => {
        // removes nbsp;
        const label = result.label.replace(/&nbsp;/g, ' ')
        // for multiselect questions
        if (Array.isArray(result.content)) {
          const answers = []
          result.content.forEach(el => answers.push(el.selection))
          resultsObj[label] = answers.join(', ')
        } else {
          // regular single select
          resultsObj[label] = result.content
        }
      })
      this.formData.results = resultsObj
    },

  },
}

</script>
