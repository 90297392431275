<template>
  <div class="custom-results-form">
    <div class="title-block">
      <h2 class="form-title">
        {{ content.title }}
      </h2>
      <p class="form-text">
        {{ content.text }}
      </p>
    </div>
    <SignUpForm />
    <p class="form-disclaimer">
      {{ content.disclaimer }}
    </p>
  </div>
</template>
<script>
import SignUpForm from './SignUpForm.vue'

export default
{
  components: {
    SignUpForm,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  created() {
  },
}

</script>
